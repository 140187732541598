<template>
  <!-- Contents -->
  <div v-show="testpass">
    <router-view
      :eventNo="Number(eventNo)"
      :eventInfo="eventInfo"
      :eventDate="Number(eventDate)"
      @refresh="onRefresh"
    ></router-view>
  </div>
  <!-- // CONTENTS -->
</template>

<script>
import EventDetailNo from '@/views/event/EventDetailNo.vue';

export default {
  name: 'EventDetailNo',
  extends: EventDetailNo,
};
</script>
